<template>
    <div class="card">
        <div class="col-md-12" style="padding-bottom:10px;">
            <div class="col-md-10" style="display: inline-block;padding-right:10px;">
                <Multiselect
                    v-model="selectedStudents"
                    v-bind="availableStudentList"
                    >
                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                        <div class="multiselect-tag is-user">
                        <img :src="option.image? option.image : emptyProfileImage">
                        {{ option.label }}
                        <i
                            v-if="!disabled"
                            @click.prevent
                            @mousedown.prevent.stop="handleTagRemove(option, $event)"
                        ></i>
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="user-image symbol symbol-circle symbol-25px" style="margin: 0 6px 0 0;border-radius: 50%;height: 22px;" :src="option.image? option.image : emptyProfileImage"> {{ option.label }}
                    </template>
                </Multiselect>
            </div>
            <button type="button" class="btn btn-sm btn-primary" @click="addToStudentList">Add</button>
        </div>
        <div class="pt-2 row" style="overflow-y:auto;max-height:250px;">
            <template v-for="(item, index) in students" :key="index">
                <div class="mb-7 d-flex align-items-center col-md-6 student-item">
                    <div class="symbol symbol-50px me-5">
                        <img :src="item.image? item.image : emptyProfileImage" class="" alt="" />
                    </div>
                    <div class="flex-grow-1">
                        <span class="text-dark fw-bolder text-hover-primary fs-6">
                            {{ item.first_name + " "+ item.last_name }}
                        </span>

                        <span class="text-muted d-block fw-bold">
                            {{ item.student_id }}
                        </span>
                    </div>
                    <span class="student-item-delete" @click="deleteFromStudentList(item)"></span>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount, computed, SetupContext, watch } from "vue";
import Multiselect from "@vueform/multiselect";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "students-widget",
  components: {
      Multiselect
  },
  props: {
      agentStudents: []
  },
  emits: ['updateStudents'],
  setup(props, {emit} ) {
    const students = ref([] as any);
    const activeStudents = ref([] as any);
    const selectedStudents = ref([] as any);
    const studentsData = computed(() => {
        const data = activeStudents.value != undefined && activeStudents.value.length > 0 ? activeStudents.value.map(x=>{
          return {value:x.id, label:x.first_name+" "+x.last_name+"("+x.student_id+")", image:x.image}}) :[];
          return data;
    });

    students.value = [];

    watch(() => props.agentStudents, (cur, prev)=>{
        if(props.agentStudents){
            students.value = props.agentStudents;
        }
        else{
            students.value = [];
        }
        getActiveStudents();
    })

    const emptyProfileImage = '/media/avatars/blank.png';

    const availableStudentList = ref({
        mode: "tags",
        placeholder: "Select student",
        searchable : true,
        options: studentsData,
    });
    
    const getActiveStudents = async() => {
        ApiService.setHeader();
        await ApiService.get("student/status/Active")
            .then(({ data }) => {
                activeStudents.value = data.data.filter(x=> x.agent_id == null && (!students.value || students.value?.filter(y=> y.id == x.id).length ===0));
            })
            .catch(({ response }) => {
            console.log('getStudents error => '+response.data.errors);
            });
    }

    const addToStudentList = () => {
        for(let index in selectedStudents.value){
            let id = selectedStudents.value[index];
            var student = activeStudents.value.filter(x=>x.id == id)[0];

            if(!students.value){
                students.value = [];
            }

            students.value.unshift({first_name :student.first_name, last_name : student.last_name, image : student.image, id : student.id, student_id : student.student_id });
            activeStudents.value = activeStudents.value.filter(x=>x.id != id);
        }
        selectedStudents.value = [];
        emit('updateStudents', students);
    }

    const deleteFromStudentList = (student) =>{
        activeStudents.value.push({
            first_name :student.first_name, 
            last_name : student.last_name,
            image : student.image, 
            id : student.id,
            student_id : student.student_id
        }); 

        students.value = students.value.filter(x=>x.id != student.id);
        emit('updateStudents', students);
    }

    onBeforeMount(async()=>{
        students.value = props.agentStudents;
        await getActiveStudents();
    });

    return {
      students,
      availableStudentList,
      addToStudentList,
      deleteFromStudentList,
      selectedStudents,
      emptyProfileImage
    };
  },
});
</script>

<style scoped>
.multiselect-tag.is-user img{
    width: 18px;
    border-radius: 50%;
    height: 18px;
    margin-right: 8px;
    border: 2px solid hsla(0,0%,100%,.7490196078431373);
}

.multiselect-tag.is-user{
    padding: 2px 0 2px 8px;
    border-radius: 10px;
}

.student-item-delete{
    display:none;
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    margin-left: 75%;
    width: 20%;
    background: url(/media/svg/custom/trash-fill.svg) center no-repeat green;
    background-size: 20px;
    opacity: 0.5;
    cursor: pointer;
}
.student-item:hover .student-item-delete{
  display:block;
  opacity: 0.5;
}

.student-item{
    position: relative;
}
</style>